<template>
  <dl class="ab-logo-list">
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/1.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/2.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/3.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/4.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/5.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/6.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/7.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/8.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/9.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/10.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/11.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/12.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/13.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/14.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/15.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/16.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/17.png" alt=""/></span>
      </div>
    </dd>
    <dd>
      <div class="">
        <span><img src="~@/assets/logo/18.png" alt=""/></span>
      </div>
    </dd>
  </dl>
  <!-- 已服务客户 -->
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>

.ab-logo-list{
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;

  /* display: block; */
  /* display: inline-block; */
  flex-wrap: wrap;
  line-height: 130px
}

.ab-logo-list dd div img{
  max-width: 100%
}
/* 已服务客户 */

// .index_seven .warp{
//   // background: #fff;
//   border-radius: 8px;
//   opacity: 0.95;
// }
dl.ab-logo-list{
  padding: 20px 50px;
}
.ab-logo-list dd{
  // float: left;
  width: 16.666666%;
}
.ab-logo-list dd div{
  text-align: center;
  border: 1px solid #eee;
  padding: 8px;
  // height: 130px;
}

.ab-logo-list dd:hover div{
  box-shadow: 8px 8px 20px 0 rgb(55 99 170 / 10%), -8px -8px 20px 0 #fff, inset 0 4px 20px 0 hsl(0deg 0% 100% / 50%);
}
</style>
