<template>
  <div class="ab-block ">
    <div class="block-title " data-aos="fade">
      {{ titleStr }}
    </div>
    <div class="block-subtitle" data-aos="fade" data-aos-delay="100">
      {{ subTitleStr }}
    </div>
    <div class="block-content" data-aos="fade-down" data-aos-delay="200">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  data: function() {
    return {
      titleStr: this.title,
      subTitleStr: this.subTitle,
    };
  },
};
</script>

<style lang="sass">
.ab-block{
  // background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;

  .block-title{
    // width: 355px;
    height: 55px;
    font-size: 40px;
    font-family: AlibabaPuHuiTiB;
    // color: #FFFFFF;
    line-height: 55px;
    text-align: center;
  }

  .block-subtitle{
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    font-family: Alibaba-PuHuiTi-R;
    font-weight: normal;
    line-height: 27px;
    opacity: 0.4;
  }
}
</style>
