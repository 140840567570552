<template>
  <div>
    <div class="index_eight">
      <slot
        ><img
          src="~@/assets/home/s_8.png"
          data-aos="fade-down"
          alt="立即探索外贸营销增长之旅！"
      /></slot>
      <a
        @click="showModal"
        data-source="footer_ad"
        onmouseover="linkDataSource(this)"
        a-type="register"
        target="_blank"
        data-aos="fade-down"
        data-aos-delay="100"
        >免费试用
      </a>
    </div>
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </div>
</template>

<script>
import inquiryModalVue from "@/components/InquiryModal2.vue";
export default {
  components: {
    inquiryModalVue,
  },

  data() {
    return {
      visible: false,
    };
  },

  methods: {
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
<style lang="sass" scoped>
/* 底部广告 */
.index_eight{
  background: url(~@/assets/home/pic_9.jpg) top center;
  height: 219px;
  text-align: center;
}
.index_eight img{
  padding-bottom: 40px;
  width:427px;
  padding-top: 51px;
}
.index_eight a{
  margin: 0px auto;
  width: 130px;
  border: 1px solid rgba(255,255,255,.5);
  font-size: 16px;
}
.index_eight a{
  border-radius: 4px;
  border: 2px solid #FFFFFF;
  height: 56px;
  color: #fff;
  width: 188px;
  display: block;
  font-size: 18px;
  text-align: center;
  line-height: 56px;
}
.index_four_tab_r .fl{
  padding-left: 80px;
}
.index_eight a:hover{
  background: #fff;
  color: #2484fc;
}
</style>
