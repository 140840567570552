const metaInfo = {
  home: {
    title: "AB客 | 外贸极客 | 一站式外贸智能营销获客解决方案",
    titleTemplate: "%s",
    // htmlAttrs: {
    //   lang: 'en',
    //   amp: true,
    // },
    meta: [
      {
        name: "keywords",
        content:
          "AB客，外贸营销，外贸B2B营销，外贸智能营销，外贸AI营销，外贸自动化营销，AI推广引流，AI快速获客， SEO优化，海关数据，精准询盘，社媒营销，邮件营销，智能建站，独立站，站群，Facebook，Linkedin",
      },
      {
        name: "description",
        content:
          "AB客全新研发的外贸极客（CRM+AI）提供一站式智能营销服务，全面实现AI自动化营销，利用AI技术帮助外贸B2B企业轻松拓展全球市场，实现高效获客和精准营销管理。提高营销效率，降低运营成本，快速实现全球扩展，成就国际市场非凡成功。主要功能：AI建站与SEO优化，AI社交媒体运营，AI推广引流，AI客服，海关数据查询，客户管理；助您高效获客和成交。",
      },
    ],
  },
  "customer-inquiry": {
    title: "构建外贸数字化营销闭环",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "询盘速递,询盘服务,品牌营销,营销资料优化,品牌官网,营销站群,行业站群,社交媒体,客户洞察",
      },
      {
        name: "description",
        content:
          "通过全渠道营销推广,为企业客户快速构建外贸营销闭环，赋能全球数字化贸易",
      },
    ],
  },
  "customer-leads": {
    title: "全球贸易数据应用平台",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "客户推荐,客户智搜,搜邮寻客,客户动态,商机挖掘,海关数据,数据分析,外贸邮箱",
      },
      {
        name: "description",
        content:
          "基于贸易数据的挖掘与分析应用，为外贸企业提供更深层的数据贸易及决策指导",
      },
    ],
  },
  scrm: {
    title: "免费CRM智能营销管理平台",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "外贸CRM,客户跟进,营销决策,智能营销,全球电话,营销邮件,即时通讯",
      },
      {
        name: "description",
        content:
          "为出海企业量身打造的营销云CRM系统,从线索到成单客户全生命周期管理",
      },
    ],
  },
  "intelligent-search": {
    title: "全球企业信息查询平台-客户智搜",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "企业详情,企业决策链,企业存续状态,买家背景调查分析,贸易情报分析,决策链分析",
      },
      {
        name: "description",
        content:
          "帮助外贸人快速核验国外买家身份,及时了解企业客户经验存续状态,有效降低和规避跨国交易风险",
      },
    ],
  },
  "search-customer": {
    title: "全球精准搜邮寻客",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "AI全网搜邮拓客,智能逆向校验,目标客户挖掘,精准获取联系方式",
      },
      {
        name: "description",
        content:
          "AI全网信息搜索挖掘,覆盖全球主流搜索引擎及社媒平台,快速锁定关键决策人职位及联系方式",
      },
    ],
  },
  iexpress: {
    title: "一站式海外营销-询盘速递",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "询盘速递,挑选购买,品牌宣传,营销推广,Facebook,SNS社媒,全域数字闭环,准入门槛低",
      },
      {
        name: "description",
        content:
          "询盘速递,以询盘数据做为服务质量和客户体验的标准,彻底解决外贸企业获客难及转化率低等问题,询盘线索支持挑选采购,更精准有效的外贸拓客营销方式",
      },
    ],
  },
  iserver: {
    title: "更经济简单的出海营销方式-询盘服务",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "询盘数量有保障,稳定高效,品牌营销,量化效果明显,SNS社媒营销",
      },
      {
        name: "description",
        content:
          "询盘服务,以询盘达标量作为服务质量的衡量指标,有效降低企业用户渠道推广及营销成本,快速激活潜客,赋能外贸精准化营销",
      },
    ],
  },
  tradeGeek: {
    title: "AB客 | 外贸极客 - 超高性价比的智能外贸营销解决方案",
    titleTemplate: "%s",
    meta: [
      {
        name: "keywords",
        content:
          "外贸极客价格, 外贸营销套餐, 价格方案, 定制外贸软件, 高效外贸营销, 智能外贸解决方案, B2B外贸工具, 智能营销套餐, 外贸工具价格",
      },
      {
        name: "description",
        content:
          "外贸极客为不同客户提供了多样化的价格方案，满足您在全球市场中的各种需求，提供从建站到全方位客户管理的所有工具； 多种价格套餐，满足不同规模企业的定制要求；同时有多重优惠活动，有超高性价比。",
      },
    ],
  },
  igeek: {
    title: "外贸极客 | 一站式外贸智能营销获客解决方案 | AI自动化营销专家",
    titleTemplate: "%s",
    meta: [
      {
        name: "keywords",
        content:
          "外贸极客, CRM, AI智能营销, 自动化营销,外贸营销解决方案, 外贸自动找客户,外贸自动开发客户, AI自动化建站, 外贸独立站,SEO优化, 社媒营销, 邮件营销, 商机监控, 客户管理, 外贸B2B, AB客，Facebook运营，Linkedin运营",
      },
      {
        name: "description",
        content:
          "外贸极客由AB客全新研发，提供CRM+AI智能外贸营销解决方案，包含团队管理、外贸AI助手、内容营销、AI建站、AISEO优化、AI广告投放、AI社媒营销、AI邮件营销、商机监控、客户管理等功能。利用智能自动化技术帮助外贸企业降低运营成本，提升获客效率，提高客户成交，实现国际市场的显著增长。",
      },
    ],
  },
  brand: {
    title: "外贸网站建设-品牌站点推广-品牌营销",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "外贸建站,营销站群,SEO优化运营,谷歌推广,智能询盘,多语种,海量响应式模板,外贸独立站",
      },
      {
        name: "description",
        content:
          "聚合海外全渠道营销,提供全面高效的营销服务,外贸网站定制和外贸独立站代运营服务,帮助用户快速提高品牌知名度,打通全渠道品牌触点,降低市场营销成本支出",
      },
    ],
  },
  imarket: {
    title: "高质量询盘线索-询盘市场",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "询盘定制,商机双重防撞单机制,全品类细分触达,询盘线索",
      },
      {
        name: "description",
        content:
          "智能化标签管理,提供真实有效的买卖双方信息,自动沉淀与触达目标客户,支持行业细分定制专属高质量询盘,助力外贸企业高效转化",
      },
    ],
  },
  customs: {
    title: "AB客海关数据,赋能全球贸易-海关数据",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content:
          "海关数据,外贸数据,进出口数据,国际贸易数据,外贸订单,进出口贸易数据,国外采购商信息",
      },
      {
        name: "description",
        content:
          "AB客海关数据,专业提供实时有效的精准海关数据,国外采购商客户订单信息,海关数据质量保证、价格优惠、效果显著,深度挖掘匹配优质客户,准确预测贸易趋势,实时掌握竞品及上下游动向,助力外贸企业开拓全球市场",
      },
    ],
  },
  "resource-help": {
    title: "帮助中心",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "常见问题,帮助中心",
      },
      {
        name: "description",
        content:
          "AB客帮助中心,提供产品文档和常见问题的搜索,包含产品应用、使用手册、常见问题等资料,我们会不断优化服务内容，让您获得更好的帮助体验",
      },
    ],
  },
  "resource-navigation": {
    title: "出海资源导航",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "出海资源导航",
      },
    ],
  },
  "resource-template": {
    title: "独立站模板",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "独立站模板",
      },
    ],
  },
  cooperation: {
    title: "渠道合作",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "渠道合作,招商加盟,资源互换",
      },
      {
        name: "description",
        content:
          "为合作伙伴提供完善的扶持体系,现诚邀您的加盟，共享跨境出海万亿蓝海市场",
      },
    ],
  },
  About: {
    title: "关于我们",
    titleTemplate: "%s-AB客",
    meta: [
      {
        name: "keywords",
        content: "AB客,牧客网络科技,外贸营销平台",
      },
      {
        name: "description",
        content:
          "上海牧客网络科技有限公司（简称“牧客”）,是一家总部位于上海的B2B跨境电商综合服务提供商,专注于外贸网络营销服务与尖端技术探索.AB客是以“大数据+人工智能”为外贸企业打造的云SaaS外贸营销平台.致力于为外贸企业提供一站式出海推广营销全流程解决方案",
      },
    ],
  },
};

export default metaInfo;
