import { render, staticRenderFns } from "./AbCustomers.vue?vue&type=template&id=870b4aee&scoped=true"
import script from "./AbCustomers.vue?vue&type=script&lang=js"
export * from "./AbCustomers.vue?vue&type=script&lang=js"
import style0 from "./AbCustomers.vue?vue&type=style&index=0&id=870b4aee&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "870b4aee",
  null
  
)

export default component.exports